import { useDispatch } from 'react-redux';
import { track } from '../../apis';
import Button from '../../components/Button';
import { Translate } from '../../i18n/translate';
import { setTrackingResult } from '../../store/slices/main/mainSlice';

const SearchResultCard = ({ list, selectTrackObject }) => {
  const dispatch = useDispatch();

  const showTrackPayment = async (referenceId, index) => {
    if (selectTrackObject) {
      selectTrackObject(index);
      return;
    }
    const { data } = await track(referenceId);
    const updated = { ...data, referenceId };
    dispatch(setTrackingResult(updated));
  };

  return list.map((item, index) => (
    <div className='border-2 border-gray4 p-5 mb-4' key={index}>
      <p className='text-16-bold mb-3'>{item.reference}</p>
      <div className='flex flex-col sm:flex-row items-baseline justify-between'>
        <div className='mb-4 sm:mb-0'>
          <p>
            <Translate value='PT_PAYMENT_INIT' />
          </p>
          <p className='font-bold'>{item.createdOn || item.receivedOn}</p>
        </div>

        <div className='mb-4 sm:mb-0'>
          <p>
            <Translate value='PT_INST_NAME_CARD' />
          </p>
          <p className='font-bold'>{item.institution}</p>
        </div>

        <div className='mb-4 sm:mb-0'>
          <p>
            <Translate value='PT_TNS_TYPE' />
          </p>
          <p className='font-bold'>
            <Translate value='PT_PAYMENT' />
          </p>
        </div>

        <Button
          variant='contained'
          color='primary'
          className='w-full sm:w-auto'
          onClick={() => {
            showTrackPayment(item.reference, index);
          }}
        >
          <Translate value='PT_TRACK_PAYMENT' />
        </Button>
      </div>
    </div>
  ));
};

export default SearchResultCard;
