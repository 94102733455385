import DOMPurify from 'dompurify';
import { useIntl } from 'react-intl';

export const Translate = ({ value, className }) => {
  const intl = useIntl();
  if (!value) return '';

  const formattedMsg = intl.formatMessage({ id: value });
  return <span className={className}>{formattedMsg}</span>;
};

const replaceVariables = (message, variables) => {
  return message.replace(/\$([a-zA-Z_][a-zA-Z0-9_]*)/g, (match, variableName) => {
    const camelCaseKey = Object.keys(variables).find(
      (key) => key.toLowerCase() === variableName.toLowerCase(),
    );
    return camelCaseKey ? variables[camelCaseKey] : match;
  });
};

export const getTranslatedValue = (val) => {
  const intl = useIntl();
  return intl.formatMessage({ id: val });
};

export const TranslateWithValues = (key, translateValues = {}) => {
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: key }, translateValues);
  // Variables not replaced with html contents- TO-Check
  return replaceVariables(formattedMsg, translateValues);
};

export const RawHtmlContent = ({ htmlContent, requireTranslate = true, translateValues = {} }) => {
  if (requireTranslate) {
    htmlContent = TranslateWithValues(htmlContent, translateValues);
  }

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
