import { ThemeProvider } from '@emotion/react';
import { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { track } from './apis';
import I18nDataProvider from './i18n/I18nDataProvider';
import Landing from './pages/Landing';
import Tracking from './pages/Tracking';
import store from './store';
import {
  selectTrackingResult,
  setFallBackUrl,
  setLocale,
  setToken,
  setTrackingResult,
} from './store/slices/main/mainSlice';
import { initRefdata } from './store/slices/refdata/action';
import { injectStore } from './utils/apiClient';

injectStore(store);

const Router = ({ referenceId }) => {
  const dispatch = useDispatch();
  const trackingResult = useSelector(selectTrackingResult);

  useEffect(() => {
    if (referenceId) {
      fetchTrackingData();
    }
  }, [referenceId]);

  const fetchTrackingData = async () => {
    try {
      const { data } = await track(referenceId);
      if (data.validationResult?.length && data.validationResult[0]?.success) {
        dispatch(setTrackingResult(data));
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
    }
  };

  return (
    <div className='p-4'>
      {!trackingResult && !referenceId && <Landing />}
      {trackingResult && <Tracking trackingResponse={trackingResult} />}
    </div>
  );
};

function App({ theme, referenceId, fallBackUrl, locale, token }) {
  useEffect(() => {
    if (token) {
      // TOKEN is EPS identifier
      store.dispatch(setToken(token));
    }
    store.dispatch(setLocale(locale));
    store.dispatch(initRefdata());
    store.dispatch(setFallBackUrl(fallBackUrl));
  }, []);

  return (
    <Provider store={store}>
      <I18nDataProvider>
        <ThemeProvider theme={theme}>
          <Router referenceId={referenceId} />
        </ThemeProvider>
      </I18nDataProvider>
    </Provider>
  );
}

export default App;
