import { combineReducers } from '@reduxjs/toolkit';
import mainSlice from './slices/main/mainSlice';
import refdataSlice from './slices/refdata/refdataSlice';

const combinedReducer = combineReducers({
  refdata: refdataSlice,
  main: mainSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'session/resetState') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
