import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { track } from '../../apis';
import Button from '../../components/Button';
import { getTranslatedValue, Translate } from '../../i18n/translate';
import { setTrackingResult } from '../../store/slices/main/mainSlice';
import FindReferenceForm from '../FindReferenceForm';

const Landing = () => {
  const [referenceId, setReferenceId] = useState('');
  const [referenceVerified, setReferenceVerified] = useState(true);
  const [showReferenceForm, setShowReferenceForm] = useState(false);
  const [hideSearch, setHideSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    const { data } = await track(referenceId.trim());
    setLoading(false);
    if (data.validationResult?.length && data.validationResult[0]?.success) {
      dispatch(setTrackingResult(data));
      setReferenceVerified(true);
    } else {
      setReferenceVerified(false);
    }
  };

  const handleReferenceChange = (e) => {
    setReferenceId(e.target.value);
    setReferenceVerified(true);
  };

  return (
  <>
      <div className='container max-w-[890px]'>
        {!hideSearch && <>
          <div className='text-18-bold sm:text-24-bold'>
            <Translate value='PT_TRACK_PAYMENT_HEADER' />
          </div>

          <div className='text-14 mt-[20px]'>
            <Translate value='PT_TRACK_PAYMENT_DESC' />
          </div>
          <div className='flex justify-between mt-[5px] gap-2'>
            <TextField
              variant='outlined'
              fullWidth
              value={referenceId}
              placeholder={getTranslatedValue('PT_REF_ENTER')}
              onChange={handleReferenceChange}
              className='w-100% sm:w-[70%] h-[60px]'
            />
            <Button
              className='sm:text-16-bold ml-1 grow hidden sm:block h-[45px] p-0'
              variant='contained'
              disabled={!referenceId || !referenceVerified}
              onClick={handleSubmit}
            >
              <Translate value='PT_SUBMIT' />
              {loading && <CircularProgress size={12} color='secondary' className='ml-2' />}
            </Button>
          </div>
          <div
            className='mt-1 underline text-sm cursor-pointer'
            onClick={() => setShowReferenceForm(true)}
          >
            <Translate value='PT_REF_NOT_FOUND' />
          </div>
          {!referenceVerified && (
            <div className='mt-2 w-full sm:w-[70%] text-sm'>
              <Translate className={'text-error'} value={'PT_REF_NOT_EXIST'} />
            </div>
          )}
          <Button
            className='w-full mt-6 sm:hidden'
            variant='contained'
            disabled={!referenceId || !referenceVerified}
            onClick={handleSubmit}
          >
            <Translate value='PT_SUBMIT' />
            {loading && <CircularProgress size={12} color='secondary' className='ml-2' />}
          </Button>
        </>}
        
        {showReferenceForm && (
          <div className='mt-7'>
            <FindReferenceForm
              setHideSearch={setHideSearch}
              onCancel={() => {
                setShowReferenceForm(false);
                setReferenceVerified(true);
              }}

            />
          </div>
        )}
      </div >
    </>
    
  );
};

export default Landing;
