import axios from 'axios';
import { setError, setLoading } from '../store/slices/main/mainSlice';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: false,
});

let store;

export const injectStore = (_store) => {
  store = _store;
};

let requestCount = 0;

apiClient.interceptors.request.use(
  async (config) => {
    requestCount++;
    if (requestCount === 1) {
      // First request, show loader
      store.dispatch(setLoading(true));
    }
    // Attach the token to the request headers
    const token = store.getState()?.main?.token;
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }

    return config;
  },
  (error) => {
    requestCount--;
    if (requestCount === 0) {
      // No more ongoing requests, hide loader
      store.dispatch(setLoading(false));
    }
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    requestCount--;
    if (requestCount === 0) {
      // No more ongoing requests, hide loader
      store.dispatch(setLoading(false));
    }
    store.dispatch(setError(null));
    return {
      success: true,
      ...response,
    };
  },
  (error) => {
    requestCount--;
    if (requestCount === 0) {
      // No more ongoing requests, hide loader
      store.dispatch(setLoading(false));
    }

    if (error.response) {
      store.dispatch(setError(error.response.data));
    } else {
      if (error.code !== 'ERR_CANCELED') {
        store.dispatch(setError({}));
      }
    }

    return {
      success: false,
    };
  },
);

export const translationClient = axios.create({
  baseURL: process.env.REACT_APP_TRANSLATION_BASE_URL,
  withCredentials: false,
});
