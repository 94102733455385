import axios from 'axios';

// Global store for cancel token sources
const cancelTokenStorage = [];

export const addCancelToken = (source) => {
  cancelTokenStorage.push(source);
};

export const clearAllCancelTokens = () => {
  while (cancelTokenStorage.length > 0) {
    const source = cancelTokenStorage.pop();
    source.cancel('api_cancelled_error');
  }
};

export const getAPIToken = () => {
  const source = axios.CancelToken.source();
  addCancelToken(source);
  return source.token;
};
