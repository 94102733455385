import { FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClientCountries, getInstitutions, getList, track } from '../../apis';
import Button from '../../components/Button';
import { CountrySelect } from '../../components/CountryDropDown';
import { InstitutionSelect } from '../../components/InstitutionDropDown';
import SearchResultCard from '../../components/SearchResultCard';
import { getTranslatedValue, Translate } from '../../i18n/translate';
import { setTrackingResult } from '../../store/slices/main/mainSlice';

const FindReferenceForm = ({ onCancel, setHideSearch }) => {
  const [countries, setCountries] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [formData, setFormData] = useState({
    institutionCountry: '',
    institutionName: '',
    studentName: '',
    studentId: '',
  });
  const [formErrors, setFormErrors] = useState({
    institutionCountry: false,
    institutionName: false,
    studentName: false,
    studentId: false,
  });
  const [dealRefList, setDealRefState] = useState([]);
  const [referenceError, setReferenceError] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getClientCountries().then((res) => {
      const { data } = res;
      setCountries(data.clientCountries);
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleBlur = (field) => {
    if (!formData[field]) {
      setFormErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    } else {
      setFormErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
    }
  };

  const isFormValid = () => {
    return (
      Object.values(formErrors).every((field) => field === false) &&
      Object.values(formData).every((field) => field !== '')
    );
  };

  const setError = (msgs) => {
    const error = msgs.map((msg) => {
      return msg.errorCode;
    });
    setReferenceError(error);
  };

  const showTrackPayment = async (referenceId) => {
    const { data } = await track(referenceId);
    const updated = { ...data, referenceId };
    dispatch(setTrackingResult(updated));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableSubmit(true);
    if (isFormValid()) {
      const request = {
        sellerId: formData.institutionName,
        studentId: formData.studentId,
        studentFullName: formData.studentName,
        tracking: 'PAYMENT',
      };
      try {
        const { data } = await getList(request);
        if (data.pledgeViewSummaries && data.pledgeViewSummaries.length) {
          if (data.pledgeViewSummaries.length > 1) {
            setReferenceError({});
            setDealRefState(data.pledgeViewSummaries);
            setHideSearch(true);
          }
          if (data.pledgeViewSummaries.length === 1) {
            setReferenceError({});
            showTrackPayment(data.pledgeViewSummaries[0].reference);
          }
        } else {
          setDealRefState([]);
          setError(data.validationResult.errors);
        }
      } finally {
        setDisableSubmit(false);
      }
    }
  };

  return (
    <div>
      {dealRefList.length == 0  && <form onSubmit={handleSubmit}>
      <div className='grid prid-cols-1 sm:grid-cols-2 gap-8'>
        <FormControl fullWidth error={formErrors.institutionCountry}>
          <label
            className={`text-sm mb-[5px] block ${formErrors.institutionCountry ? 'text-error' : ''
              }`}
          >
            <Translate value='PT_INST_COUNTRY' />
          </label>
          <CountrySelect
            countries={countries}
            name='institutionCountry'
            value={formData.institutionCountry}
            placeholder={getTranslatedValue('PT_SELECT_COUNTRY')}
            setCountry={async (value) => {
              handleChange({
                target: {
                  name: 'institutionCountry',
                  value: value,
                },
              });
              const { data } = await getInstitutions({ country: value });
              setInstitutions(data.sellers);
            }}
            onBlur={() => handleBlur('institutionCountry')}
            label=''
          />

          {formErrors.institutionCountry && (
            <p style={{ color: 'red' }}>
              <Translate value='PT_INST_COUNTRY_REQUIRED' />{' '}
            </p>
          )}
        </FormControl>

        <FormControl fullWidth error={formErrors.institutionName}>
          <label
            className={`text-sm mb-[5px] block ${formErrors.institutionName ? 'text-error' : ''}`}
          >
            <Translate value='PT_INST_NAME' />
          </label>
          <InstitutionSelect
            institutions={institutions}
            dataFetching={formData.institutionCountry && !institutions.length}
            disabled={!formData.institutionCountry}
            placeholder={getTranslatedValue('PT_INST_ENTER')}
            setInstitution={(value) => {
              handleChange({
                target: {
                  name: 'institutionName',
                  value: value,
                },
              });
            }}
          />
          {formErrors.institutionName && (
            <p style={{ color: 'red' }}>
              <Translate value='PT_INST_NAME_REQUIRED' />
            </p>
          )}
        </FormControl>

        <div>
          <label
            className={`text-sm mb-[5px] block ${formErrors.studentName ? 'text-error' : ''}`}
          >
            <Translate value='PT_STUDENT_NAME' />
          </label>
          <TextField
            fullWidth
            variant='outlined'
            label=''
            name='studentName'
            placeholder={getTranslatedValue('PT_NAME_ENTER')}
            value={formData.studentName}
            onChange={handleChange}
            onBlur={() => handleBlur('studentName')}
            error={formErrors.studentName}
            helperText={formErrors.studentName ? 'Student Name is required' : ''}
          />
        </div>

        <div>
          <label
            className={`text-sm mb-[5px] block ${formErrors.studentName ? 'text-error' : ''}`}
          >
            <Translate value='PT_STUDENT_ID' />
          </label>
          <TextField
            fullWidth
            variant='outlined'
            label=''
            name='studentId'
            placeholder={getTranslatedValue('PT_STUDENT_ID_ENTER')}
            value={formData.studentId}
            onChange={handleChange}
            onBlur={() => handleBlur('studentId')}
            error={formErrors.studentId}
            helperText={formErrors.studentId ? getTranslatedValue('PT_STUDENT_ID_REQUIRED') : ''}
          />
        </div>
      </div>

      <div className='flex justify-center mt-6'>
        <Button
          variant='contained'
          color='secondary'
          onClick={() =>
            onCancel() &&
            setFormData({
              institutionCountry: '',
              institutionName: '',
              studentName: '',
              studentId: '',
            })
          }
        >
          <Translate value='PT_CANCEL' />
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          disabled={!isFormValid() || disableSubmit}
          className='ml-2'
        >
          <Translate value='PT_SUBMIT' />
        </Button>
      </div>
    </form>}

      {referenceError &&
        Object.keys(referenceError).map((key) => {
          return (
            <p className='text-error text-sm mt-5' key={key}>
              <Translate value={referenceError[key]} />
            </p>
          );
        })}

      {dealRefList.length > 0 && (
        <div>
           <div className='text-18-bold sm:text-24-bold'>
            <Translate value='PT_TRACK_PAYMENT_HEADER' />
          </div>
          <p className='text-16-bold my-5'>
            <Translate value='PT_SEARCH_RES_HEADER' />
          </p>
          <SearchResultCard list={dealRefList} />
        </div>
      )}
    </div>
  );
};

export default FindReferenceForm;
