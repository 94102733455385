import { apiClient } from '../utils/apiClient';
import { getAPIToken } from './cancelTokens';

export const getClientCountries = () => {
  return apiClient.get('/tracking/clientCountries', {}, { cancelToken: getAPIToken() });
};

export const getInstitutions = ({ country }) => {
  return apiClient.get(`/tracking/institution/${country}`, { cancelToken: getAPIToken() });
};

export const getList = (data) => {
  return apiClient.post('/services/reference/track', data, { cancelToken: getAPIToken() });
};

export const track = (referenceId) => {
  return apiClient.get(`/services/track?reference=${referenceId}`, { cancelToken: getAPIToken() });
};

export const getRefdata = () => {
  return apiClient.get('/refdataCache', { cancelToken: getAPIToken() });
};

export const subscribeNotification = (phoneNumber, countryCode, pledgeId) => {
  return apiClient.put(
    `/buyer/notify/sms?pledgeId=${pledgeId}&countryCode=${countryCode}&phoneNumber=${phoneNumber}`,
    null,
    { cancelToken: getAPIToken() },
  );
};
