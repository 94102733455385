import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LitElement, css, html } from 'lit';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import store from './store';
import { setLocale } from './store/slices/main/mainSlice';
import { createThemeForApp } from './style/theme';

class ConveraPaymentTracking extends LitElement {
  root;
  static properties = {
    dataSellerInfo: { type: String, attribute: 'data-seller-info' },
    dataTheme: { type: String, attribute: 'data-theme' },
    dataLocale: { type: String, attribute: 'data-locale' },
    referenceId: { type: String, attribute: 'data-reference-id' },
    fallBackUrl: { type: String, attribute: 'data-fallback-url' },
    epsToken: { type: String, attribute: 'data-eps-token' },
  };

  static styles = css`
    :host {
      font-size: 14px;
      text-align: left;
    }
    .c-mount-point {
      display: contents;
    }
  `;

  constructor() {
    super();
    this.dataSellerInfo = '';
    this.dataTheme = '{}';
    this.dataLocale = 'en_GB';
    this.referenceId = '';
    this.fallBackUrl = '';
    this.epsToken = '';
  }

  createLink(rel, href, crossOrigin = false) {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    if (crossOrigin) {
      link.crossOrigin = 'anonymous';
    }
    return link;
  }

  createScript(src, defer = false, async = false) {
    const script = document.createElement('script');
    script.src = src;
    script.defer = defer;
    script.async = async;
    return script;
  }

  createDefaultStyle(fontName, primaryColor, secondaryColor, accentColors, warningColor) {
    const style = document.createElement('style');
    style.innerHTML = `
        :root {
          --custom-font-name: ${fontName};
          --custom-primary-color: ${primaryColor};
          --custom-secondary-color: ${secondaryColor};
          --custom-accent-color-light: ${accentColors[0]};
          --custom-accent-color: ${accentColors[1]};
          --custom-accent-color-dark: ${accentColors[2]};
          --custom-warning-color: ${warningColor};
        }

        :host {
          font-family: var(--custom-font-name);
          --custom-font-name: ${fontName};
          --custom-primary-color: ${primaryColor};
          --custom-secondary-color: ${secondaryColor};
          --custom-accent-color-light: ${accentColors[0]};
          --custom-accent-color: ${accentColors[1]};
          --custom-accent-color-dark: ${accentColors[2]};
          --custom-warning-color: ${warningColor};
        }
    `;
    return style;
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('id', 'convera-payment-tracking');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    store.dispatch({ type: 'session/resetState' });
    const mountPoint = this.renderRoot.querySelector('.c-mount-point');
    if (mountPoint) {
      const result = this.root.unmount();
      console.debug(result ? 'sucess' : 'failed');
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name == 'data-locale' && oldValue && oldValue != newValue) {
      store.dispatch(setLocale(newValue));
    }
    super.attributeChangedCallback(name, oldValue, newValue);
  }

  firstUpdated() {
    const mainHead = document.head;
    const themeObject = JSON.parse(this.dataTheme);
    const fontUrl =
      themeObject?.fontUrl ||
      'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap';
    const fontName = themeObject?.fontName || '"Noto Sans", sans-serif';
    const primaryColor = themeObject?.primaryColor || '#26E1CF';
    const secondaryColor = themeObject?.secondaryColor || '#001E37';
    const accentColors = themeObject?.accentColors || ['#EEECFF', '#715AF9', '#160E6D'];
    const warningColor = themeObject?.warningColor || '#FFE0BD';
    const primaryButtonColor = themeObject?.primaryButtonColor || '#0A0337';

    mainHead.appendChild(this.createLink('preconnect', 'https://fonts.googleapis.com'));
    mainHead.appendChild(this.createLink('preconnect', 'https://fonts.gstatic.com', true));
    mainHead.appendChild(this.createLink('stylesheet', fontUrl));
    mainHead.appendChild(
      this.createLink(
        'stylesheet',
        'https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap',
      ),
    );
    this.renderRoot.appendChild(
      this.createLink('stylesheet', process.env.REACT_APP_CDN_LINK + 'static/css/main.css'),
    );

    const quantumMetricScript = this.createScript(process.env.REACT_APP_QM_SCRIPT, true, true);
    this.renderRoot.appendChild(quantumMetricScript);

    this.renderRoot.appendChild(
      this.createDefaultStyle(fontName, primaryColor, secondaryColor, accentColors, warningColor),
    );

    const mountPoint = document.createElement('div');
    mountPoint.className = 'c-mount-point';
    this.renderRoot.appendChild(mountPoint);

    const emotionCache = createCache({
      key: 'convera-payment-tracking-component',
      prepend: true,
      container: mountPoint,
    });

    this.root = createRoot(mountPoint);

    const theme = createThemeForApp(
      mountPoint,
      fontName,
      primaryColor,
      secondaryColor,
      primaryButtonColor,
    );

    this.root.render(
      <CacheProvider value={emotionCache}>
        <App
          theme={theme}
          referenceId={this.referenceId}
          fallBackUrl={this.fallBackUrl}
          token={this.epsToken}
          locale={this.dataLocale}
        />
      </CacheProvider>,
      mountPoint,
    );
  }

  render() {
    return html``;
  }
}

customElements.define('convera-payment-tracking', ConveraPaymentTracking);
