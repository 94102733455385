import MuiButton from '@mui/material/Button';
import { styled } from '@mui/system';

const Button = styled(MuiButton)({
  padding: '14px 28px',
  fontWeight: 'bold',
});

Button.defaultProps = {
  disableElevation: true,
};

export default Button;
