import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRefdata } from '../../../apis';

export const initRefdata = createAsyncThunk('refdata/init', async () => {
  try {
    const response = await getRefdata();
    return response.data;
  } catch (error) {
    console.error(`Refdata API failed ${error.message}`);
  }
});
